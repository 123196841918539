<template>
  <section class="home-page">
    <main-slider :style="sliderVisibilityStyle" />
    <nav-category :categories="categoriesList" />

    <client-only>
      <template v-if="isLoggedIn">
        <transition name="fade" mode="out-in">
          <group-recently v-show="hasRecentlyGames" show-arrows show-all-btn />
        </transition>

        <transition name="fade" mode="out-in">
          <group-favorites v-if="favoriteGames.length" show-arrows show-all-btn />
        </transition>
      </template>
    </client-only>

    <group-games v-for="category in categoriesList" :key="category.id" :category="category" />

    <atomic-seo-text
      v-if="pageContent?.currentLocaleData?.pageMeta?.seoText"
      v-bind="pageContent.currentLocaleData.pageMeta.seoText"
    />
  </section>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { CIHomePage, ICollection } from '~/types';

  const contentParams = {
    contentKey: 'homePageContent',
    contentRoute: ['pages', 'home'],
    isPage: true,
  };
  const { getContentData } = useContentLogic<CIHomePage>(contentParams);
  const { data: pageContent } = await useLazyAsyncData(contentParams.contentKey, () => getContentData());

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { getContent } = useProjectMethods();
  const categoriesList = computed(() => {
    const finalList: ICollection[] = [];
    const contentCategories: string[] =
      getContent(pageContent.value?.currentLocaleData, pageContent.value?.defaultLocaleData, 'categories') || [];

    contentCategories.forEach(category => {
      const foundCategory = gameCollections.value?.find(collection => collection.identity === category);
      if (foundCategory) finalList.push(foundCategory);
    });

    return finalList;
  });

  const gamesStore = useGamesStore();
  const { gameCollections, favoriteGames, hasRecentlyGames } = storeToRefs(gamesStore);

  const sliderVisibilityHidden = ref<boolean>(false);

  const sliderVisibilityStyle = computed(() => {
    return { visibility: !sliderVisibilityHidden.value ? 'visible' : 'hidden' };
  });
</script>

<style src="~/assets/styles/pages/home.scss" lang="scss" />
